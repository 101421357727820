import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutSurpriseCustom"
import BgImg from "../../../images/CounterCulture_Big.jpg"
import Romans from "../../../images/practiceTitle.jpeg"
import Friends from "../../../images/friendsDayEvent.jpeg"
import More from "../../../images/moreSermons.jpg"
import Rise from "../../../images/Easter2022.jpg"
import CounterCulture from "../../../images/counterCulture_title.jpeg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Counter Culture"
    SecondText="Check out sermons from our Counter Culture sermon series"
  >
    <SEO title="Sermons - Counter Culture" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
         <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="What Are We Aiming For?"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/aiming-for"
        date="August 28, 2022"
        pastor="Pastor Jackie"

      />
         <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Table of Nations"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/table-of-nations"
        date="August 21, 2022"
        pastor="Pastor Chad"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Brand New Day"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/brand-new-day"
        date="August 14, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="How to Lead like Noah"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/lead-like-noah"
        date="July 31, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Get in the Boat"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/get-in-the-boat"
        date="July 24, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Playing the Long Game"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/the-long-game"
        date="July 17, 2022"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="The Tale of Two Lives"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/tale-of-two-lives"
        date="July 10, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Reverse the Curse"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/reverse-the-curse"
        date="July 3, 2022"
        pastor="Pastor Chad"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Trouble in Paradise"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/trouble-in-paradise"
        date="June 26, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Your Best Life Now"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/best-life-now"
        date="June 19, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Animals and Humans"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/animals-and-humans"
        date="June 12, 2022"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Fish and Fowl"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/fish-and-fowl"
        date="June 5, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture Sermon Series Title"
        sermonTitle="Light and Dark"
        sermonSeries="Counter Culture"
        sermonLink="/surprise/sermons/light-and-dark"
        date="May 22, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/surprise/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Surprise"
      />
    </Grid>
  </Layout>
)

export default IndexPage
